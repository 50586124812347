import React from "react";
import { Card, Button, Col } from "react-bootstrap";
import { Link } from "react-router-dom";  // Import Link from react-router-dom

const OximoProductCard = ({ product }) => {
  return (
    <Col md={4} className="mb-4">
      <Card className="shadow">
        <Card.Img variant="top" src={product.foto_1} alt={product.symbol} />
        <Card.Body>
          <Card.Title>{product.short_name_en}</Card.Title>
          <Card.Text>
            {product.price ? `$${product.price}` : "Click below for details"}
          </Card.Text>
          <Link to={`/product/${product.symbol}`}>
            <Button variant="primary">View More Details</Button>
          </Link>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default OximoProductCard;
