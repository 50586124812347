import React, { useState, useEffect } from "react";
import { Container, Row, Pagination } from "react-bootstrap";
import axios from "axios";
import OximoSearchBar from "./OximoSearchBar";
import OximoProductCard from "./OximoProductCard";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

const OximoProductPage = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const productsPerPage = 12;

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          `https://api.mazesai.com/api/oximo/?page=${currentPage}&page_size=${productsPerPage}`
        );
        setProducts(response.data.results);
        setTotalPages(Math.ceil(response.data.count / productsPerPage)); // Calculate total pages based on total count
        setFilteredProducts(response.data.results); // Set initial products
      } catch (error) {
        console.error("Error fetching products", error);
      }
    };

    fetchProducts();
  }, [currentPage]); // Fetch products when the page changes

  const handleSearch = (query) => {
    const filtered = products.filter((product) =>
      product.short_name_en.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredProducts(filtered);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber); // Update current page
  };

  const renderPagination = () => {
    const visiblePages = 2; // Number of pages to show before and after the current page
    let startPage = Math.max(currentPage - visiblePages, 1);
    let endPage = Math.min(currentPage + visiblePages, totalPages);

    const pages = [];

    // Add first page
    if (startPage > 1) {
      pages.push(
        <Pagination.Item key={1} onClick={() => handlePageChange(1)}>
          1
        </Pagination.Item>
      );

      if (startPage > 2) {
        pages.push(<Pagination.Ellipsis key="start-ellipsis" disabled />);
      }
    }

    // Add visible pages
    for (let page = startPage; page <= endPage; page++) {
      pages.push(
        <Pagination.Item
          key={page}
          active={page === currentPage}
          onClick={() => handlePageChange(page)}
        >
          {page}
        </Pagination.Item>
      );
    }

    // Add last page
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pages.push(<Pagination.Ellipsis key="end-ellipsis" disabled />);
      }

      pages.push(
        <Pagination.Item
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }

    return pages;
  };

  return (
    <div>
      <Navbar />
      <Container>
        <h1 className="my-4">Oximo Wipers</h1>
        <OximoSearchBar onSearch={handleSearch} />
        <Row>
          {filteredProducts.map((product) => (
            <OximoProductCard key={product.symbol} product={product} />
          ))}
        </Row>

        <Pagination className="my-4">
          <Pagination.Prev
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          />
          {renderPagination()}
          <Pagination.Next
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
          />
        </Pagination>
      </Container>
      <Footer />
    </div>
  );
};

export default OximoProductPage;
