import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import Slider from "react-slick";
import axios from "axios";
import { useParams } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

const OximoProductDetail = () => {
  const [product, setProduct] = useState(null);
  const { symbol } = useParams(); 

  useEffect(() => {
    const fetchProductDetails = async () => {
      try { 
        // const response = await axios.get(`https://api.mazesai.com/api/oximo/${symbol}/`);
        const response = await axios.get(`https://api.mazesai.com/api/oximo/${symbol}/`);
        setProduct(response.data);
      } catch (error) {
        console.error("Error fetching product details", error);
      }
    };

    fetchProductDetails();
  }, [symbol]);

  if (!product) return <div>Loading...</div>;

  return (
    <div>
    <Navbar />
    <Container className="my-5">
      <Row>
        <Col md={6}>
          <Slider {...{
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
          }}>
            {[...Array(12)].map((_, i) => {
              const foto = product[`foto_${i + 1}`];
              return foto ? (
                <div key={i}>
                  <Image src={foto} alt={`foto-${i + 1}`} fluid />
                </div>
              ) : null;
            })}
          </Slider>
        </Col>

        <Col md={6}>
          <h2>{product.short_name_en}</h2>
          <p>{product.long_description_en}</p>
          {product.price ? (
            <p><strong>Price:</strong> ${product.price}</p>
          ) : (
            <p><strong>Price:</strong> Contact for price</p>
          )}

          <Button variant="primary" href={`https://wa.me/2349098370120?text=I'm interested in the Oximo Wiper: ${product.short_name_en}`} target="_blank">
            Buy Now via WhatsApp
          </Button>
        </Col>
      </Row>
    </Container>
    <Footer />
    </div>
  );
};

export default OximoProductDetail;
