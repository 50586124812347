import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCart } from '../../actions/cartActions';
import { searchAdvanceItems, searchItems } from '../../actions/itemActions';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import styles from './styles.module.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faShoppingCart } from '@fortawesome/free-solid-svg-icons';

function NavScrollExample() {
  let tokenReady = [];
  const { cart, loading } = useSelector((state) => state.cartReducer);
  const { token } = useSelector((state) => state.authReducer);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showPopupSearch, setShowPopupSearch] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [make, setMake] = useState('');
  const [model, setModel] = useState('');
  const [year, setYear] = useState('');
  const [engineType, setEngineType] = useState('');
  const [vehicleType, setVehicleType] = useState('');
  const [vehicleData, setVehicleData] = useState([]);

  const handleNormalSearch = () => {
    console.log(keyword);
    dispatch(searchItems(keyword))
    navigate('/product', { state: { keyword: keyword, category: '' } });
  };

  const handleAdvancedSearch = () => {
    let searchParams = {
      make: make,
      model: model,
      engineType: engineType,
    };

    // Check if the year is a range
    if (year && year.start && year.end) {
      searchParams = {
        ...searchParams,
        year: { start: year.start, end: year.end },
      };
    } else if (year) {
      searchParams = {
        ...searchParams,
        year: year,
      };
    }

    // Check if the vehicle type is a range
    if (vehicleType && vehicleType.start && vehicleType.end) {
      searchParams = {
        ...searchParams,
        vehicleType: { start: vehicleType.start, end: vehicleType.end },
      };
    } else if (vehicleType) {
      searchParams = {
        ...searchParams,
        vehicleType: vehicleType,
      };
    }

    console.log(searchParams);
    dispatch(searchAdvanceItems(searchParams));
    navigate('/product', { state: { searchParams: searchParams, category: '' } });
  };

  useEffect(() => {
    if (token !== null && token !== 'undefined') {
      const tokenParse = JSON.parse(token);
      dispatch(getCart(tokenParse?.id));
    }

    const fetchVehicleData = async () => {
      try {
        const totalPages = 8; // Set the total number of pages you want to fetch
        let allVehicleData = {};

        // Use Promise.all to fetch data from all pages concurrently
        const promises = Array.from({ length: totalPages }, (_, index) => {
          const page = index + 1;
          const nextPage = `${process.env.REACT_APP_API_URL}api/vehiclesdataapi/?page=${page}`;
          return axios.get(nextPage);
        });

        const responses = await Promise.all(promises);

        responses.forEach((response) => {
          const pageVehicleData = response.data;
          Object.keys(pageVehicleData).forEach((make) => {
            if (!allVehicleData[make]) {
              allVehicleData[make] = pageVehicleData[make];
            } else {
              Object.keys(pageVehicleData[make]).forEach((model) => {
                if (!allVehicleData[make][model]) {
                  allVehicleData[make][model] = pageVehicleData[make][model];
                } else {
                  allVehicleData[make][model] = {
                    ...allVehicleData[make][model],
                    ...pageVehicleData[make][model],
                  };
                }
              });
            }
          });
        });

        setVehicleData(allVehicleData);
      } catch (error) {
        console.error('Error fetching vehicle data:', error);
      }
    };

    fetchVehicleData();
  }, [dispatch, token]);

  const handlePopupSearch = () => {
    setShowPopupSearch(true);
  };

  const handleClosePopupSearch = () => {
    setShowPopupSearch(false);
  };

  const handleClear = () => {
    setMake('');
    setModel('');
    setYear('');
    setEngineType('');
    setVehicleType('');
  };

  return (
    <>
      <Navbar bg="light" expand="lg">
        <Container fluid>
          <Navbar.Brand href="/">
            <img
              src="https://i0.wp.com/www.kamsiparts.com/wp-content/uploads/2021/12/Kamsi_Quality_Logo_plainbg.png?w=738&ssl=1"
              alt="logo"
              style={{ width: '100%', maxWidth: '150px' }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-lg-0" style={{ maxHeight: '100px' }} navbarScroll>
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/product">
                <Link to={'/product'} style={{ textDecoration: 'none', color: 'black' }}>
                  Products
                </Link>
              </Nav.Link>
              <Nav.Link href="/product">
                <Link to={'/oximo-product'} style={{ textDecoration: 'none', color: 'black' }}>
                  Wipers 
                </Link>
              </Nav.Link>
              <Nav.Link href="https://www.kamsiparts.com/about-us/">Abous Us</Nav.Link>
              <NavDropdown title="B2B Portal" id="navbarScrollingDropdown">
                <NavDropdown.Item href="https://www.kamsiparts.com/workshop/">Workshops/Garages</NavDropdown.Item>
                <NavDropdown.Item href="https://www.kamsiparts.com/distributors-retailers/">Distributors/Retailers</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="https://www.kamsiparts.com/fleet/">Fleets/Corporate Clients</NavDropdown.Item>
                <NavDropdown.Item href="https://www.kamsiparts.com/3rd-party-suppliers/">3rd Party Suppliers</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="https://www.kamsiparts.com/faqs/">FAQs</Nav.Link>
              <Nav.Link href="tel:+23498370120">Call: +2349098370120 </Nav.Link>
            </Nav>

            <div className={styles.search}>
              <Form.Control
                type="text"
                placeholder="Keyword"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
              />
              <Button variant="primary" onClick={handleNormalSearch}>
                Search
              </Button>
              <Button variant="secondary" onClick={handlePopupSearch}>
                Advanced Search
              </Button>
            </div>

            <div className={styles.cart_container}>
              <Link to={'/company-dashboard'} style={{ textDecoration: 'none', color: 'black' }}>
                <div className={styles.login_signup}>
                  {token ? (
                    <div>
                      <FontAwesomeIcon icon={faUser} size="lg" />
                    </div>
                  ) : (
                    <span>Login</span>
                  )}
                </div>
              </Link>

              <div className={styles.cart}>
                <Link to={'/cartView'} state={{ cartId: cart?.id }}>
                  <FontAwesomeIcon icon={faShoppingCart} size="lg" />
                </Link>
                {!loading ? (
                  tokenReady ? (
                    cart?.quantity !== 0 ? (
                      <div className={styles.buble}>
                        <span>{cart?.quantity}</span>
                      </div>
                    ) : (
                      <span></span>
                    )
                  ) : (
                    <span></span>
                  )
                ) : (
                  <span>Loading . . .</span>
                )}
              </div>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Modal show={showPopupSearch} onHide={handleClosePopupSearch}>
        <Modal.Header closeButton>
          <Modal.Title>Advanced Search</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* Make Dropdown */}
            <Form.Group controlId="makeDropdown">
              <Form.Label>Make</Form.Label>
              <Form.Control as="select" value={make} onChange={(e) => setMake(e.target.value)}>
                <option value="">Select Make</option>
                {Object.keys(vehicleData).sort().map(makeOption => (
                  <option key={makeOption} value={makeOption}>{makeOption}</option>
                ))}
              </Form.Control>
            </Form.Group>

            {/* Model Dropdown */}
            {make && (
              <Form.Group controlId="modelDropdown">
                <Form.Label>Model</Form.Label>
                <Form.Control as="select" value={model} onChange={(e) => setModel(e.target.value)}>
                  <option value="">Select Model</option>
                  {Object.keys(vehicleData[make]).sort().map(modelOption => (
                    <option key={modelOption} value={modelOption}>{modelOption}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            )}

            {/* Year Dropdown */}
            {make && model && (
              <Form.Group controlId="yearDropdown">
                <Form.Label>Year</Form.Label>
                <Form.Control as="select" value={year} onChange={(e) => setYear(e.target.value)}>
                  <option value="">Select Year</option>
                  {Object.keys(vehicleData[make][model]).map(yearOption => (
                    <option key={yearOption} value={yearOption}>{yearOption}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            )}

            {/* Engine Type Dropdown */}
            {make && model && year && (
              <Form.Group controlId="engineTypeDropdown">
                <Form.Label>Engine Type</Form.Label>
                <Form.Control as="select" value={engineType} onChange={(e) => setEngineType(e.target.value)}>
                  <option value="">Select Engine Type</option>
                  {vehicleData[make][model][year].engine_types.map(engineTypeOption => (
                    <option key={engineTypeOption} value={engineTypeOption}>{engineTypeOption}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            )}

            {/* Vehicle Type Dropdown */}
            {make && model && year && engineType && (
              <Form.Group controlId="vehicleTypeDropdown">
                <Form.Label>Vehicle Type</Form.Label>
                <Form.Control as="select" value={vehicleType} onChange={(e) => setVehicleType(e.target.value)}>
                  <option value="">Select Vehicle Type</option>
                  {vehicleData[make][model][year].vehicle_types.map((vehicleTypeOption, index) => (
                    <option key={index} value={vehicleTypeOption}>{vehicleTypeOption}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePopupSearch}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAdvancedSearch}>
            Search
          </Button>
          <Button variant="danger" onClick={handleClear}>
            Clear
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default NavScrollExample;
