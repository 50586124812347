import React, { useState } from "react";
import { FormControl } from "react-bootstrap";

const OximoSearchBar = ({ onSearch }) => {
  const [query, setQuery] = useState("");

  const handleChange = (e) => {
    setQuery(e.target.value);
    onSearch(e.target.value);
  };

  return (
    <FormControl
      type="text"
      placeholder="Search Oximo Wipers..."
      value={query}
      onChange={handleChange}
      className="mb-4"
    />
  );
};

export default OximoSearchBar;
